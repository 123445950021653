/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unresolved
import Sidebar from 'Screens/Components/Sidebar/Sidebar';
import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  TextArea,
} from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { sendGlobalAlert } from 'Services/AlertsService';

export default function Alerts() {
  const [loading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [category, setCategory] = useState(null);
  const [categoryText, setCategoryText] = useState('Choose Alert Category');
  const [alertBody, setAlertBody] = useState('');

  const handleCategoryChange = (e) => {
    setCategoryText(e.target.value);
    if (e.target.value === 'Corporate Actions') {
      setCategory(1);
    } else if (e.target.value === 'Market Status') {
      setCategory(3);
    } else if (e.target.value === 'Announcements') {
      setCategory(5);
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successLoading = async () => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Please Wait"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Sending Alert...
      </ReactBSAlert>,
    );
    await sendGlobalAlert({ category, message: alertBody });
    setAlert(
      <ReactBSAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title="Sent!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Alert has been sent!
      </ReactBSAlert>,
    );
  };

  const warningWithConfirmAndCancelMessage = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => successLoading()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={loading ? 'Sending...' : 'Yes, send it!'}
        cancelBtnText="Cancel"
        disabled={loading}
        showCancel
        btnSize=""
      >
        You will not be able to unsend this Alert!
      </ReactBSAlert>,
    );
  };

  return (
    <>
      {alert}
      <div className="d-flex flex-row h-screen bg-zinc-800 w-screen">
        <div
          className="w-screen"
          style={{
            width: '100%',
            marginLeft: '18%',
            marginRight: '5%',
            marginTop: '5%',
          }}
        >
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Send Global Alerts</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row style={{ marginLeft: '14.5%', flexDirection: 'row', alignItems: 'center' }}>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup
                        caret
                        className="btn-block"
                        color="primary"
                        data-toggle="dropdown"
                        id="dropdownMenuButton"
                        type="button"
                      >
                        {categoryText}
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuButton">
                        <DropdownItem header>Alerts Categories</DropdownItem>
                        <DropdownItem
                          onClick={(e) => handleCategoryChange(e)}
                          value="Corporate Actions"
                        >
                          Corporate Actions
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => handleCategoryChange(e)}
                          value="Market Status"
                        >
                          Market Status
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => handleCategoryChange(e)}
                          value="Announcements"
                        >
                          Announcements
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Row style={{ marginLeft: '5%' }}>
                      <Col sm="10">
                        <Label>Alert Body</Label>
                        <FormGroup>
                          <spanarea
                            className="textarea"
                            autoComplete="off"
                            rows={5}
                            cols={40}
                            color="blue"
                            style={{ resize: 'none' }}
                            value={alertBody}
                            onChange={(e) => setAlertBody(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                  <CardFooter className="text-center">
                    <Button color="primary" onClick={warningWithConfirmAndCancelMessage} disabled={!category || !alertBody}>
                      Send Alert
                    </Button>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}
