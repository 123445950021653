// src/Screens/Reconciliation/components/EventDetailsModal.js
/* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Inspector } from 'react-inspector';
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { getApexAccountDetails } from 'Services/AccountServices';
import SortingTable from '../../Components/SortingTable/SortingTable';
import PropTypes from 'prop-types';

// Define event types
const EVENT_TYPES = {
  ALL: 'all',
  SHARES_TO_BOOK: 'SHARES_TO_BOOK',
  SHARES_BOOKED: 'SHARES_BOOKED',
  NO_SHARES: 'NO_SHARES',
  // Add other event types here
};

// Define fields for each event type
const EVENT_TYPE_FIELDS = {
  [EVENT_TYPES.ALL]: [
    { label: 'Event ID', key: 'id' },
    { label: 'External ID', key: 'externalId' },
    { label: 'Trade Date', key: 'tradeDate' },
    { label: 'Event Type', key: 'eventType' },
    { label: 'Status', key: 'status' },
    { label: 'Side', key: 'side' },
    { label: 'Symbol', key: 'symbol' },
    { label: 'Share Quantity', key: 'shareQuantity' },
    { label: 'Average Price', key: 'avgPrice' },
    { label: 'Notional', key: 'notional' },
    { label: 'Date Time', key: 'dateTime' },
    { label: 'S3 Path', key: 's3Path' },
    { label: 'S3 Last Modified', key: 's3LastModified' },
    // Add other common fields here
  ],
  [EVENT_TYPES.SHARES_TO_BOOK]: [
    { label: 'Event ID', key: 'id' },
    { label: 'External ID', key: 'externalId' },
    { label: 'Share Quantity', key: 'shareQuantity' },
    { label: 'Average Price', key: 'avgPrice' },
    { label: 'Notional', key: 'notional' },
    { label: 'Status', key: 'status' },
    // Add other SHARES_TO_BOOK specific fields here
  ],
  [EVENT_TYPES.SHARES_BOOKED]: [
    { label: 'Event ID', key: 'id' },
    { label: 'External ID', key: 'externalId' },
    { label: 'Booked Quantity', key: 'bookedQuantity' },
    { label: 'Book Price', key: 'bookPrice' },
    { label: 'Total Notional', key: 'totalNotional' },
    { label: 'Status', key: 'status' },
    // Add other SHARES_BOOKED specific fields here
  ],
  [EVENT_TYPES.NO_SHARES]: [
    { label: 'Event ID', key: 'id' },
    { label: 'External ID', key: 'externalId' },
    { label: 'Reason', key: 'reason' },
    { label: 'Reported At', key: 'reportedAt' },
    { label: 'Status', key: 'status' },
    // Add other NO_SHARES specific fields here
  ],
  // Add other event types and their fields here
};

function EventDetailsModal({ isOpen, toggle, orderDetails }) {
  const [isPayloadOpen, setIsPayloadOpen] = useState(false); // State for Raw Payload toggle
  const [activeTab, setActiveTab] = useState('1'); // State for Tabs

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Determine the event type, default to ALL if undefined
  const eventType = orderDetails.eventType || EVENT_TYPES.ALL;

  // Fetch account details using react-query
  const {
    data: accountDetails,
    isLoading: isLoadingAccount,
    isError: isErrorAccount,
    error: errorAccount,
  } = useQuery(
    ['getApexAccountDetails', orderDetails.account],
    () => getApexAccountDetails(orderDetails.account),
    { enabled: !!orderDetails.account } // Only run if account exists
  );

  // Safely parse payload
  let parsedPayload = {};
  try {
    parsedPayload =
      typeof orderDetails.payload === 'string'
        ? JSON.parse(orderDetails.payload)
        : orderDetails.payload;
  } catch (error) {
    console.error('Error parsing payload:', error);
    parsedPayload = { error: 'Invalid JSON payload' };
  }

  // Get fields based on event type
  const fieldsToDisplay = useMemo(() => {
    return EVENT_TYPE_FIELDS[eventType] || EVENT_TYPE_FIELDS[EVENT_TYPES.ALL];
  }, [eventType]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      aria-labelledby="event-details-modal-title"
      scrollable // Makes modal content scrollable if content is long
    >
      <ModalHeader toggle={toggle} id="event-details-modal-title">
        Event Details - {orderDetails.externalId || orderDetails.id}
      </ModalHeader>
      <ModalBody>
        {/* Event Information */}
        <Card className="mb-3">
          <CardHeader>
            <h4>Event Information</h4>
          </CardHeader>
          <CardBody>
            <SortingTable
              thead={[
                { text: 'Field' },
                { text: 'Value' },
              ]}
              tbody={fieldsToDisplay.map((field) => ({
                data: [
                  { text: field.label },
                  {
                    text:
                      typeof orderDetails[field.key] === 'number'
                        ? orderDetails[field.key].toFixed(2)
                        : orderDetails[field.key] || 'N/A',
                  },
                ],
              }))}
            />
          </CardBody>
        </Card>

        {/* Raw Payload Section */}
        <Card className="mb-3">
          <CardHeader
            onClick={() => setIsPayloadOpen(!isPayloadOpen)}
            style={{ cursor: 'pointer' }}
            aria-expanded={isPayloadOpen}
            aria-controls="raw-payload-section"
          >
            <h4>Raw Payload</h4>
          </CardHeader>
          <Collapse isOpen={isPayloadOpen}>
            <CardBody id="raw-payload-section">
              <Inspector
                data={parsedPayload}
                expandLevel={2}
                theme="chromeLight"
              />
            </CardBody>
          </Collapse>
        </Card>

        {/* Account Details with Tabs */}
        {orderDetails.account && (
          <Card className="mb-3">
            <CardHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggleTab('1'); }}
                    style={{ cursor: 'pointer' }}
                    aria-label="Account Overview Tab"
                  >
                    Overview
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggleTab('2'); }}
                    style={{ cursor: 'pointer' }}
                    aria-label="Account Entitlements Tab"
                  >
                    Entitlements
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggleTab('3'); }}
                    style={{ cursor: 'pointer' }}
                    aria-label="Customer Profile Tab"
                  >
                    Customer Profile
                  </NavLink>
                </NavItem>
                {/* Add more NavItems for additional sections if needed */}
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                {/* Overview Tab */}
                <TabPane tabId="1">
                  {isLoadingAccount && (
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" /> Loading account overview...
                    </div>
                  )}
                  {isErrorAccount && (
                    <div style={{ color: 'red' }}>
                      Error loading account overview: {errorAccount.message}
                    </div>
                  )}
                  {accountDetails && accountDetails.accountOverview && (
                    <SortingTable
                      thead={[
                        { text: 'Label' },
                        { text: 'Value' },
                      ]}
                      tbody={[
                        {
                          data: [
                            { text: 'Account Type' },
                            { text: accountDetails.accountOverview.accountType || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Account Sub Type' },
                            { text: accountDetails.accountOverview.accountSubType || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Margin Type' },
                            { text: accountDetails.accountOverview.marginType || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Account Number' },
                            { text: accountDetails.accountOverview.accountNumber || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Maintenance Requirement' },
                            { text: accountDetails.accountOverview.maintenanceRequirement || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Opened On' },
                            { text: accountDetails.accountOverview.openedOn || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Status' },
                            { text: accountDetails.accountOverview.accountStatus || 'N/A' },
                          ],
                        },
                      ]}
                    />
                  )}
                </TabPane>

                {/* Entitlements Tab */}
                <TabPane tabId="2">
                  {isLoadingAccount && (
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" /> Loading account entitlements...
                    </div>
                  )}
                  {isErrorAccount && (
                    <div style={{ color: 'red' }}>
                      Error loading account entitlements: {errorAccount.message}
                    </div>
                  )}
                  {accountDetails && accountDetails.accountEntitlements && (
                    <SortingTable
                      thead={[
                        { text: 'Label' },
                        { text: 'Value' },
                      ]}
                      tbody={[
                        {
                          data: [
                            { text: 'FDIC Sweep' },
                            { text: accountDetails.accountEntitlements.fdicSweep ? 'True' : 'False' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Instant Deposits' },
                            { text: accountDetails.accountEntitlements.instantDeposits || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Instant Deposits Limit' },
                            { text: accountDetails.accountEntitlements.instantDepositLimit || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Equities' },
                            { text: accountDetails.accountEntitlements.equities || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Options' },
                            { text: accountDetails.accountEntitlements.options || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Instant Buying Power' },
                            { text: accountDetails.accountEntitlements.instantBp || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Dividend Reinvestment' },
                            { text: accountDetails.accountEntitlements.dividendReinvestment ? 'True' : 'False' },
                          ],
                        },
                      ]}
                    />
                  )}
                </TabPane>

                {/* Customer Profile Tab */}
                <TabPane tabId="3">
                  {isLoadingAccount && (
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" /> Loading customer profile...
                    </div>
                  )}
                  {isErrorAccount && (
                    <div style={{ color: 'red' }}>
                      Error loading customer profile: {errorAccount.message}
                    </div>
                  )}
                  {accountDetails && accountDetails.customerProfile && (
                    <SortingTable
                      thead={[
                        { text: 'Label' },
                        { text: 'Value' },
                      ]}
                      tbody={[
                        {
                          data: [
                            { text: 'Name' },
                            { text: accountDetails.customerProfile.name || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Address' },
                            { text: accountDetails.customerProfile.address || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'DOB' },
                            { text: accountDetails.customerProfile.dob || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'SSN' },
                            { text: accountDetails.customerProfile.ssn || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Phone Number' },
                            { text: accountDetails.customerProfile.phone || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Email' },
                            { text: accountDetails.customerProfile.email || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Citizenship' },
                            { text: accountDetails.customerProfile.citizenship || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Employment' },
                            { text: accountDetails.customerProfile.employment || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Employer Name' },
                            { text: accountDetails.customerProfile.employerName || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Job Title' },
                            { text: accountDetails.customerProfile.jobTitle || 'N/A' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Politically Exposed' },
                            { text: accountDetails.customerProfile.politicallyExposed ? 'True' : 'False' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Public Company' },
                            { text: accountDetails.customerProfile.publicCompany ? 'True' : 'False' },
                          ],
                        },
                        {
                          data: [
                            { text: 'Broker Dealer' },
                            { text: accountDetails.customerProfile.brokerDealer || 'N/A' },
                          ],
                        },
                      ]}
                    />
                  )}
                </TabPane>

                {/* TODO: Add more TabPanes for additional account detail sections as needed */}
              </TabContent>
            </CardBody>
          </Card>
        )}

        {/* FIXME: If no account is associated with the event, you might want to handle it gracefully */}
        {!orderDetails.account && (
          <Card className="mb-3">
            <CardHeader>
              <h4>Account Information</h4>
            </CardHeader>
            <CardBody>
              <p>No account information available for this event.</p>
            </CardBody>
          </Card>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={toggle}
          aria-label="Close Event Details Modal"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

EventDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
};

export default EventDetailsModal;
