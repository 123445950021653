/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
} from 'reactstrap';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { getAllTransfers, getAllAdjustments } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SimpleTable from '../Components/SortingTable/SortingTable';
import ReactTable from '../Components/ReactTable/ReactTable';

function Transfers() {
  const navigate = useNavigate();

  function handlePress(externalTransferId) {
    navigate(`/admin/transfers/${externalTransferId}`);
  }

  const getAllAdjustmentsQuery = useQuery('getAllAdjustments', () => getAllAdjustments(), {
    onSuccess: (data) => {
      setAdjustmentData(data);
    },
  });

  const [adjustmentData, setAdjustmentData] = React.useState(getAllAdjustmentsQuery.data && getAllAdjustmentsQuery.data);

  // eslint-disable-next-line no-unused-vars
  const getAllTransfersQuery = useQuery('getAllTransfers', () => getAllTransfers(), {
    onSuccess: (data) => {
      setData(data.map((prop, key) => ({
        id: key,
        amount: prop.amount,
        account: prop.account,
        status: prop.state,
        reason: prop.reason,
        transferId: prop.transferid,
        externalTransferId: prop.externaltransferid,
        type: prop.type,
        uuid: prop.id,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePress(prop.externaltransferid);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      })));
    },
  });

  const [data, setData] = React.useState(getAllTransfersQuery.data && getAllTransfersQuery.data.map((prop, key) => ({
    id: key,
    amount: prop.amount,
    account: prop.account,
    status: prop.state,
    reason: prop.reason,
    transferId: prop.transferid,
    externalTransferId: prop.externaltransferid,
    type: prop.type,
    uuid: prop.id,
    dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
    lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
    actions: (
    // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            handlePress(prop.externaltransferid);
          }}
          color="#000000"
          size="sm"
        >
          <i className="tim-icons icon-double-right" />
        </Button>
        {' '}

      </div>
    ),

  })));

  function handleRefresh() {
    getAllTransfersQuery.refetch();
  }

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Transfers</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={() => {
                      handleRefresh();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                  {' '}
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Amount',
                          accessor: 'amount',
                        },
                        {
                          Header: 'Account',
                          accessor: 'account',
                        },
                        {
                          Header: 'Overall Status',
                          accessor: 'status',
                        },
                        {
                          Header: 'Reason',
                          accessor: 'reason',
                        },
                        {
                          Header: 'Transfer ID',
                          accessor: 'transferId',
                        },
                        {
                          Header: 'External Transfer ID',
                          accessor: 'externalTransferId',
                        },
                        {
                          Header: 'Type',
                          accessor: 'type',
                        },
                        {
                          Header: 'UUID',
                          accessor: 'uuid',
                        },
                        {
                          Header: 'Date Submitted',
                          accessor: 'dateSubmitted',
                        },
                        {
                          Header: 'Last Updated',
                          accessor: 'lastUpdated',
                        },
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span> loading </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <Col md={6} className="ml-auto mr-auto">
                  <h2 className="text-center">Dividends</h2>
                </Col>
                <CardBody>
                  {adjustmentData && adjustmentData.length > 0 ? (
                    <SimpleTable
                      thead={Object.keys(adjustmentData[0]).map((key) => ({
                        text: key,
                      }))}
                      tbody={adjustmentData.map((obj) => ({
                        data: Object.values(obj).map((value) => ({
                          text: String(value),
                        })),
                      }))}
                    />
                  ) : (
                    <span> loading... </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Transfers;
