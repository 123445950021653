/* eslint-disable no-console */

import AuthenticationService from './Auth.service';

// Set the API URL based on environment
const apiUrl = process.env.REACT_APP_ENVIRONMENT === 'preprod'
  ? 'https://yvuldtbwgc.execute-api.us-east-1.amazonaws.com/preprod'
  : process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? 'https://i4z79373kk.execute-api.us-east-1.amazonaws.com/prod'
    : 'http://localhost:3001'; // Use local API for 'local' env

export const APIRequestWithAuth = async (type, endpoint, body) => {
  // Skip authentication if in local environment
  const headers = {
    'Content-Type': 'application/json',
  };

  if (process.env.REACT_APP_BUILD_ENV !== 'local') {
    const { idToken } = await AuthenticationService.getSession();
    const jwt = idToken.toString();
    headers.Authorization = `Bearer ${jwt}`;
  }

  let options;

  if (type === 'GET' || type === 'DELETE') {
    if (body) {
      throw new Error('GET request cannot contain a body');
    }
    options = {
      method: type,
      headers,
    };
  } else if (type === 'POST' || type === 'PATCH' || type === 'PUT') {
    options = {
      method: type,
      headers,
      body: JSON.stringify(body),
    };
  }

  try {
    const response = await fetch(`${apiUrl}${endpoint}`, options);
    // Remove logging of response object before consuming body
    // console.log('response', response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    // console.log('response data', data);
    return data;
  } catch (error) {
    console.error('Error in APIRequestWithAuth:', error);
    throw error;
  }
};
