/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import routes from 'routes.js';
import { useQuery } from 'react-query';
import { getAllApexWithSketch, searchUsers } from 'Services/AccountServices';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import SearchBar from 'Screens/Whitelist/components/SearchBar';
import Sidebar from '../Components/Sidebar/Sidebar';
import ReactTable from '../Components/ReactTable/ReactTable';
import UpdateAccountStateModal from './components/UpdateAccountStateModal';

function Accounts() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const gridRef = useRef();
  const [chartData, setChartData] = useState([]);
  const [searching, setSearching] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleRowDoubleClick = (event) => {
    setSelectedAccount(event.data);
    setModalOpen(true);
  };
  const [columnDefs, setColumnDefs] = useState([
    { field: 'user_name', filter: true, headerName: 'Cognito Id' },
    { field: 'user_id', filter: true, headerName: 'User Id' },
    { field: 'first_name', filter: true, headerName: 'First Name' },
    { field: 'last_name', filter: true, headerName: 'Last Name' },
    { field: 'email', filter: true, headerName: 'Email' },
    { field: 'username', filter: true, headerName: 'Username' },
    { field: 'location', filter: true, headerName: 'Location' },
    { field: 'join_date', filter: true, headerName: 'Join Date' },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const onSearch = async (searchText) => {
    const res = await searchUsers(searchText);
    setChartData(res);
  };

  function handlePress(accountNumber) {
    navigate(`/admin/accounts/${accountNumber}`);
  }

  // eslint-disable-next-line no-unused-vars
  const getAllApexAccountsQuery = useQuery('getAllApexWithSketch', () => getAllApexWithSketch(), {
    onSuccess: (data) => {
      setData(data.map((prop, key) => ({
        id: key,
        first_name: prop.first_name,
        last_name: prop.last_name,
        email: prop.email,
        state: prop.state,
        accountNumber: prop.accountnumber,
        account: prop.account_id,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            <Button
              disabled={prop.state !== 'ACCEPTED'}
              onClick={() => {
                handlePress(prop.accountnumber);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      })));
    },
  });

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'customStats',
    }),
    [],
  );

  const [data, setData] = React.useState(getAllApexAccountsQuery.data && getAllApexAccountsQuery.data.map((prop, key) => ({
    id: key,
    first_name: prop.first_name,
    last_name: prop.last_name,
    email: prop.email,
    state: prop.state,
    accountNumber: prop.accountnumber,
    account: prop.account_id,
    dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
    lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
    actions: (
    // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          disabled={prop.state !== 'ACCEPTED'}
          onClick={() => {
            handlePress(prop.accountnumber);
          }}
          color="#000000"
          size="sm"
        >
          <i className="tim-icons icon-double-right" />
        </Button>
        {' '}

      </div>
    ),

  })));

  function handleRefresh() {
    getAllApexAccountsQuery.refetch();
  }

  return (
    <div className="flex h-screen bg-zinc-800">
      <UpdateAccountStateModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        account={selectedAccount}
      />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Accounts</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={() => {
                      handleRefresh();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                  {' '}
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      tableRoute="accounts"
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Account ID',
                          accessor: 'account',
                        },
                        {
                          Header: 'Account Number',
                          accessor: 'accountNumber',
                        },
                        {
                          Header: 'First Name',
                          accessor: 'first_name',
                        },
                        {
                          Header: 'Last Name',
                          accessor: 'last_name',
                        },
                        {
                          Header: 'Email',
                          accessor: 'email',
                        },

                        {
                          Header: 'State',
                          accessor: 'state',
                        },

                        {
                          Header: 'Date Submitted',
                          accessor: 'dateSubmitted',
                        },
                        {
                          Header: 'Last Updated',
                          accessor: 'lastUpdated',
                        },
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span> loading... </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          style={{
            marginTop: '5%',
            width: '80%',
            marginLeft: '18%',
            marginRight: '5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <h1>User Search ~ Update Internal State</h1>
            <h3>
              (Double click a row to update the internal state of the user)
            </h3>
          </div>
          <SearchBar
            onSearch={onSearch}
            setSearching={setSearching}
            whitelistQuery={{ data: [] }}
            setChartData={setChartData}
          />
          <div className="bg-zinc-800" style={{ width: '100%', height: 1500 }}>
            <AgGridReact
              className="ag-theme-alpine"
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows
              rowSelection="multiple"
              rowData={chartData}
              onRowDoubleClicked={handleRowDoubleClick}
              sideBar={sideBar}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              marginTop: 20,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Accounts;
