import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function ApexAccountEntitlementsTable({ accountNumber }) {
  const [accountEntitlementsTable, setAccountEntitlementsTable] = useState([]);

  const { isLoading, isError, error } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (data) => {
        const accountEntitlements = [
          {
            label: 'FDIC Sweep',
            value: data?.accountEntitlements?.fdicSweep ? 'True' : 'False',
          },
          {
            label: 'Instant Deposits',
            value: data?.accountEntitlements?.instantDeposits || 'N/A',
          },
          {
            label: 'Instant Deposits Limit',
            value: data?.accountEntitlements?.instantDepositLimit || 'N/A',
          },
          {
            label: 'Equities',
            value: data?.accountEntitlements?.equities || 'N/A',
          },
          {
            label: 'Options',
            value: data?.accountEntitlements?.options || 'N/A',
          },
          {
            label: 'Instant Buying Power',
            value: data?.accountEntitlements?.instantBp || 'N/A',
          },
          {
            label: 'Dividend Reinvestment',
            value: data?.accountEntitlements?.dividendReinvestment || 'N/A',
          },
        ];
        setAccountEntitlementsTable(accountEntitlements);
      },
    },
  );

  if (isLoading) {
    return <p>Loading account entitlements...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load account entitlements.'}
      </p>
    );
  }

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Account Entitlements and Permissions For:
            {' '}
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {accountEntitlementsTable.length > 0 ? (
            <SimpleTable
              thead={[{ text: 'Label' }, { text: 'Value' }]}
              tbody={accountEntitlementsTable.map((row) => ({
                data: [{ text: row.label }, { text: row.value }],
              }))}
            />
          ) : (
            <p>No entitlements found.</p>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default ApexAccountEntitlementsTable;
