import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useQuery } from 'react-query';
import SimpleTable from '../../Components/SortingTable/SortingTable';
import { getApexAccountDetails } from '../../../Services/AccountServices';

function ApexAccountOverviewTable({ accountNumber }) {
  const [accountOverviewTable, setAccountOverviewTable] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const {
    isLoading, isError, error,
  } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (data) => {
        const acctOverview = [
          { label: 'Account Type', value: data?.accountOverview?.accountType },
          { label: 'Account Sub Type', value: data?.accountOverview?.accountSubType },
          { label: 'Margin Type', value: data?.accountOverview?.marginType },
          { label: 'Account Number', value: data?.accountOverview?.accountNumber },
          { label: 'Maintenance Requirement', value: data?.accountOverview?.maintenanceRequirement },
          { label: 'Opened On', value: data?.accountOverview?.openedOn },
          {
            label: 'Status',
            value: (
              <div className="flex items-center gap-2">
                {data?.accountOverview?.accountStatus}
                <button
                  type="button"
                  onClick={() => setShowStatusModal(true)}
                  className="ml-2 px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Change Status
                </button>
              </div>
            ),
          },
        ];
        setAccountOverviewTable(acctOverview);
      },
    },
  );

  // TODO: Uncomment this when the backend is ready
  // const mutation = useMutation(updateAccountStatus, {
  //   onSuccess: () => {
  //     setShowStatusModal(false);
  //     // Refetch the account details after successful update
  //     queryClient.invalidateQueries(['getApexAccountDetails', accountNumber]);
  //   },
  //   onError: (error) => {
  //     alert(`Error updating status: ${error.message}`);
  //   },
  // });

  const handleStatusSubmit = () => {
    if (newStatus) {
      // mutation.mutate({ accountNumber, newStatus });
    } else {
      alert('Please select a status');
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load account details.'}
      </p>
    );
  }

  return (
    <>
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Account Overview For:
              {' '}
              {accountNumber}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {accountOverviewTable && accountOverviewTable.length > 1 ? (
              <SimpleTable
                thead={[{ text: 'Label' }, { text: 'Value' }]}
                tbody={accountOverviewTable.map((row) => ({
                  data: [{ text: row.label }, { text: row.value }],
                }))}
              />
            ) : (
              <span> loading... </span>
            )}
          </CardBody>
        </Card>
      </Col>

      {/* Modal for Changing Status */}
      <Modal isOpen={showStatusModal} toggle={() => setShowStatusModal(!showStatusModal)}>
        <ModalHeader toggle={() => setShowStatusModal(!showStatusModal)}>Change Account Status</ModalHeader>
        <ModalBody>
          <label htmlFor="statusSelect">Select New Status:</label>
          <select
            id="statusSelect"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="form-control"
          >
            <option value="">-- Select Status --</option>
            <option value="status 1">Status 1</option>
            <option value="status 2">Status 2</option>
            <option value="status 3">Status 3</option>
          </select>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleStatusSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={() => setShowStatusModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ApexAccountOverviewTable;
