/* eslint-disable import/no-unresolved */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllApexWithSketch } from 'Services/AccountServices';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function Applications() {
  const navigate = useNavigate();

  function handlePress(requestid, accountNum) {
    navigate(`/admin/applications/${requestid}`, { state: { accountNum } });
  }

  function handleRefresh() {
    // eslint-disable-next-line no-use-before-define
    getAllApexAccountsQuery.refetch();
  }

  // eslint-disable-next-line no-unused-vars
  const getAllApexAccountsQuery = useQuery('getAllApexWithSketch', () => getAllApexWithSketch(), {
    onSuccess: (data) => {
      // eslint-disable-next-line no-use-before-define
      setData(data.map((prop, key) => ({
        id: key,
        first_name: prop.first_name,
        last_name: prop.last_name,
        email: prop.email,
        requestid: prop.requestid,
        state: prop.state,
        source: prop.source,
        accountnumber: prop.accountnumber,
        account: prop.account_id,
        status: prop.account_status,
        internal_status: prop?.status,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePress(prop.requestid, prop.accountnumber);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      })));
    },
  });

  const [data, setData] = React.useState(
    getAllApexAccountsQuery.data
      && getAllApexAccountsQuery.data.map((prop, key) => ({
        id: key,
        first_name: prop.first_name,
        last_name: prop.last_name,
        email: prop.email,
        requestid: prop.requestid,
        state: prop.state,
        source: prop.source,
        accountnumber: prop.accountnumber,
        account: prop.account_id,
        status: prop.account_status,
        internal_status: prop?.status,
        dateSubmitted: moment(prop.originaldatetime).format(
          'MM/DD/YYYY HH:mm:ss A',
        ),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePress(prop.requestid, prop.accountnumber);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}
          </div>
        ),
      })),
  );

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Applications</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={() => {
                      handleRefresh();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                  {' '}
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      tableRoute="accounts"
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Account ID',
                          accessor: 'account',
                        },
                        {
                          Header: 'Account Number',
                          accessor: 'accountnumber',
                        },
                        {
                          Header: 'Overall Status',
                          accessor: 'status',
                        },
                        {
                          Header: 'First Name',
                          accessor: 'first_name',
                        },
                        {
                          Header: 'Last Name',
                          accessor: 'last_name',
                        },
                        {
                          Header: 'Email',
                          accessor: 'email',
                        },

                        {
                          Header: 'Source',
                          accessor: 'source',
                        },
                        {
                          Header: 'State',
                          accessor: 'state',
                        },
                        {
                          Header: 'Internal Status',
                          accessor: 'internal_status',
                        },
                        {
                          Header: 'Sketch Request ID',
                          accessor: 'requestid',
                        },
                        {
                          Header: 'Date Submitted',
                          accessor: 'dateSubmitted',
                        },
                        {
                          Header: 'Last Updated',
                          accessor: 'lastUpdated',
                        },
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span> loading... </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Applications;
