import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import GroupDetailsBox from './components/GroupDetailsBox';
import GroupAumDetails from './components/GroupAumDetails';
import GroupProposals from './components/GroupProposals';
import GroupWithdrawals from './components/GroupWithdrawals';
import ScrollSidebar from './components/ScrollSidebar';

export default function GroupDetailsScreen() {
  const { groupId } = useParams();
  const detailsRef = useRef(null);
  const aumDetailsRef = useRef(null);
  const proposalsRef = useRef(null);
  const withdrawalsRef = useRef(null);

  const sections = [
    { id: 'details', label: 'Group Details', ref: detailsRef },
    { id: 'aumDetails', label: 'AUM Details', ref: aumDetailsRef },
    { id: 'proposals', label: 'Proposals', ref: proposalsRef },
    { id: 'withdrawals', label: 'Withdrawals', ref: withdrawalsRef },
  ];

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <span className="mb-5">
            Group
            {' '}
            {groupId}
            {' '}
            Details
          </span>
        </header>
      </div>
      <div
        className="flex justify-center items-start mt-10"
        style={{ marginLeft: '18%', marginRight: '5%', marginBottom: '5%' }}
      >
        <div ref={detailsRef} className="mb-10">
          <GroupDetailsBox groupId={groupId} />
        </div>
        <div ref={aumDetailsRef} className="mb-10">
          <GroupAumDetails groupId={groupId} />
        </div>
        <div ref={proposalsRef} className="mb-10">
          <GroupProposals groupId={groupId} />
        </div>
        <div ref={withdrawalsRef} className="mb-10">
          <GroupWithdrawals groupId={groupId} />
        </div>
      </div>
      <ScrollSidebar sections={sections} />
    </div>
  );
}
