/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import AuthenticationService from 'Services/Auth.service';

const initialState = {
  email: null,
};

// Helper functions to handle localStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('session', serializedState);
  } catch (e) {
    console.warn('Could not save state', e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('session');
    if (serializedState === null) return initialState;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn('Could not load state', e);
    return initialState;
  }
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: loadFromLocalStorage(),
  reducers: {
    login: (state, action) => {
      const { attributes } = action.payload || {};
      if (attributes) {
        Object.assign(state, attributes); // Merge all attributes into the state
        saveToLocalStorage(state);
      } else {
        console.warn('Attributes missing or incomplete:', attributes);
      }
    },
    logout: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });
      localStorage.removeItem('session');
    },
  },
});

export const { login, logout } = sessionSlice.actions;

export const updateSession = () => async (dispatch) => {
  try {
    const attributes = await AuthenticationService.getUserAttributes();

    // Directly access the email attribute
    const emailAttribute = attributes.email;

    if (!emailAttribute) {
      console.error('Email attribute is missing.');
      return;
    }

    dispatch(login({ attributes: { email: emailAttribute } }));
  } catch (error) {
    console.error('Error updating session:', error);
  }
};

export default sessionSlice.reducer;
