/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
  getApexInvestigationDetails, getInternalInvestigationHistory, getUserInfoByApexAccountNumber, getIdentityDocsByAccountNumber,
  updateInternalInvestigationState, manuallyApproveIndeterminateInvestigation,
} from 'Services/AccountServices';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import SimpleTable from '../Components/SortingTable/SortingTable';
import IdentityTable from 'Screens/Components/IdentityTable/IdentityTable';
import Sidebar from '../Components/Sidebar/Sidebar';

const alertBodies = {
  updateInvestigationState: {
    warning: 'Are you sure you want to update the investigation state?',
    success: 'Investigation state updated successfully!',
    loading: 'Please wait...',
  },
  manuallyApproveIndeterminateInvestigation: {
    warning: 'Are you sure you want to manually approve the indeterminate investigation?',
    success: 'Indeterminate investigation manually approved!',
    loading: 'Please wait...',
  },
};

export default function ApplicationDetails() {
  const { requestid } = useParams();
  const location = useLocation();
  const { accountNum } = location.state;
  const [alert, setAlert] = React.useState(null);
  const [alertBody, setAlertBody] = React.useState(null);
  const [summaryResultsTable, setSummaryResultsTable] = React.useState(null);
  const [identityRequestTable, setIdentityRequestTable] = React.useState(null);
  const [identityResultStateTable, setIdentityResultStateTable] = React.useState(null);
  const [identityResultStateDetailsTable, setIdentityResultStateDetailsTable] = React.useState(null);
  const [sanctionsNewsProfilesTable, setSanctionsNewsProfilesTable] = React.useState(null);
  const [dndbProfilesTable, setDndbProfilesTable] = React.useState(null);
  const [cipCategoriesTable, setCipCategoriesTable] = React.useState(null);
  const [internalInvestigationTable, setInternalInvestigationTable] = React.useState(null);
  const [allIdentityDocumentsTable, setAllIdentityDocumentsTable] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);
  const [investigationDropDownValue, setInvestigationDropDownValue] = React.useState(null);

  const navigate = useNavigate();

  const hideAlert = () => {
    setAlert(null);
  };

  const handlePressNewsProfiles = () => {
    navigate(`/admin/applications/${requestid}/sanctionsnewsprofiles`);
  };
  const handlePressDNDBProfiles = () => {
    navigate(`/admin/applications/${requestid}/dndbprofiles`);
  };
  const handlePressCIPCategories = () => {
    navigate(`/admin/applications/${requestid}/cipdetails`, { state: { accountNumber: identityRequestTable.account } });
  };

  const getApexInvestigationDetailsQuery = useQuery('getApexInvestigationDetails', () => getApexInvestigationDetails(requestid), {
    onSuccess: (data) => {
      const summaryResults = Object.keys(data.summaryResults).map((checkType) => ({
        checkType,
        status: data.summaryResults[checkType],
      }));
      setSummaryResultsTable(summaryResults);

      setIdentityRequestTable(data.identityVerificationDetailsRequest);
      const identityResultState = [
        {
          Field: 'Overall Investigation State',
          Value:
            data.identityVerificationDetailsResultState
              .overallInvestigationState,
        },
        {
          Field: 'Evaluated State',
          Value: data.identityVerificationDetailsResultState.evaluatedState,
        },
        {
          Field: 'Data Sources',
          Value: data.identityVerificationDetailsResultState.dataSources,
        },
        {
          Field: 'Verification State',
          Value: data.identityVerificationDetailsResultState.verificationState,
        },
        {
          Field: 'Verification Reasons',
          Value: Object.entries(
            data.identityVerificationDetailsResultState.verificationReasons,
          )
            .map(([key, value]) => `${key}: ${value}`)
            .join('; '),
        },
      ];
      setIdentityResultStateTable(identityResultState);

      const identityResultStateDetails = [
        { Field: 'Reject Reasons', Value: Object.values(data.identityResultStateDetails.rejectReasons).join('; ') },
        { Field: 'Accept', Value: Object.keys(data.identityResultStateDetails.accept).length > 0 ? 'Yes' : 'No' },
        { Field: 'Indeterminate', Value: Object.keys(data.identityResultStateDetails.indeterminate).length > 0 ? 'Yes' : 'No' },
        { Field: 'Error Code', Value: data.identityResultStateDetails.errorCode ?? 'N/A' },
        { Field: 'Error Message', Value: data.identityResultStateDetails.errorMessage ?? 'N/A' },
      ];
      setIdentityResultStateDetailsTable(identityResultStateDetails);

      setSanctionsNewsProfilesTable(data.sanctionsNewsProfiles);

      setDndbProfilesTable(data.dndbProfiles);

      setCipCategoriesTable(data.cipCategoriesResults);
    },
  });

  const getInternalInvestigationQuery = useQuery(
    'getInternalInvestigation',
    () => getInternalInvestigationHistory(accountNum),
    {
      onSuccess: (data) => {
        setInternalInvestigationTable(data);
      },
    },
  );

  const handleRefreshInternalHistory = () => {
    getInternalInvestigationQuery.refetch();
  };

  const getUserQuery = useQuery('getUser', () => getUserInfoByApexAccountNumber(accountNum), {
    onSuccess: (data) => {
      setUserInfo(data);
    },
  });

  const getIdentityDocsQuery = useQuery('getIdentityDocs', () => getIdentityDocsByAccountNumber(accountNum), {
    onSuccess: (data) => {
      setAllIdentityDocumentsTable(data);
    },
  });

  const updateInvestigationMutation = useMutation(
    updateInternalInvestigationState,
    {
      onSuccess: () => {
        getInternalInvestigationQuery.refetch();
        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title="Sent!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            {alertBodies.updateInvestigationState.success}
          </ReactBSAlert>,
        );
      },
      onError: (error) => {
        setAlert(
          <ReactBSAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="danger"
            btnSize=""
          >
            {error}
          </ReactBSAlert>,
        );
      },
      onMutate: (body) => {
        setAlert(
          <ReactBSAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title="Please Wait"
            onConfirm={() => hideAlert()}
            showConfirm={false}
          >
            {alertBodies.updateInvestigationState.loading}
          </ReactBSAlert>,
        );
      },
    },
  );

  const manuallyApproveIndeterminateInvestigationMutation = useMutation(
    manuallyApproveIndeterminateInvestigation,
    {
      onSuccess: () => {
        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title="Sent!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            {alertBodies.manuallyApproveIndeterminateInvestigation.success}
          </ReactBSAlert>,
        );
        getApexInvestigationDetailsQuery.refetch();
        getInternalInvestigationQuery.refetch();
      },
      onError: (error) => {
        setAlert(
          <ReactBSAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="danger"
            btnSize=""
          >
            {error.message}
          </ReactBSAlert>,
        );
      },
      onMutate: () => {
        setAlert(
          <ReactBSAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title="Please Wait"
            onConfirm={() => hideAlert()}
            showConfirm={false}
          >
            {alertBodies.manuallyApproveIndeterminateInvestigation.loading}
          </ReactBSAlert>,
        );
      },
    },
  );

  const warningWithConfirmAndCancelMessage = (mutation, body, alertType) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => mutation.mutate(body)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        disabled={mutation.isLoading}
        showCancel
        btnSize=""
      >
        {alertBodies[alertType].warning}
      </ReactBSAlert>,
    );
  };

  return (
    <>
      {alert}
      <div className="flex h-screen bg-zinc-800">
        <Sidebar routes={routes} activeColor="blue" />
        <div className="flex justify-center items-start mt-10">
          <Col md={6} className="ml-auto mr-auto">
            <h2 className="text-center">Investigation Details</h2>
          </Col>
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Hedge User Info for Request:
                      {requestid}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {userInfo ? (
                      <SimpleTable
                        thead={[
                          { text: 'First Name' },
                          { text: 'Last Name' },
                          { text: 'Email' },
                          { text: 'Cognito User' },
                        ]}
                        tbody={[
                          {
                            data: [
                              { text: userInfo?.User?.first_name },
                              { text: userInfo?.User?.last_name },
                              { text: userInfo?.User?.email },
                              { text: userInfo?.User?.user_name },
                            ],
                          },
                        ]}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Results Summary for Request:
                      {requestid}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {summaryResultsTable && summaryResultsTable.length > 0 ? (
                      <SimpleTable
                        thead={[{ text: 'Check Type' }, { text: 'Status' }]}
                        tbody={summaryResultsTable.map((row) => ({
                          data: [{ text: row.checkType }, { text: row.status }],
                        }))}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Identity Verification Details for Request:
                      {requestid}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {identityRequestTable ? (
                      <SimpleTable
                        thead={[{ text: 'Field' }, { text: 'Value' }]}
                        tbody={Object.entries(identityRequestTable).map(
                          ([field, value]) => ({
                            data: [{ text: field }, { text: value }],
                          }),
                        )}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Identity Result State for Request:
                      {requestid}
                    </CardTitle>
                    {identityResultStateTable
                      && identityResultStateTable[0]?.Value
                        === 'INDETERMINATE' && (
                        <div>
                          <Button
                            color="primary"
                            onClick={() => warningWithConfirmAndCancelMessage(
                              manuallyApproveIndeterminateInvestigationMutation,
                              {
                                accountNum,
                                investigationRequestId: requestid,
                              },
                              'manuallyApproveIndeterminateInvestigation',
                            )}
                          >
                            Manually Approve Indeterminate Investigation
                          </Button>
                        </div>
                    )}
                  </CardHeader>
                  <CardBody>
                    {identityResultStateTable ? (
                      <SimpleTable
                        thead={[{ text: 'Field' }, { text: 'Value' }]}
                        tbody={identityResultStateTable.map((row) => ({
                          data: [{ text: row.Field }, { text: row.Value }],
                        }))}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Identity Result State Details for Request:
                      {requestid}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {identityResultStateDetailsTable ? (
                      <SimpleTable
                        thead={[{ text: 'Field' }, { text: 'Value' }]}
                        tbody={identityResultStateDetailsTable.map((row) => ({
                          data: [{ text: row.Field }, { text: row.Value }],
                        }))}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {sanctionsNewsProfilesTable
            && sanctionsNewsProfilesTable.length > 0 && (
              <div
                style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
              >
                <Row className="mt-5">
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Sanctions News Profiles for Request:
                          {requestid}
                        </CardTitle>
                        <Button
                          onClick={() => {
                            handlePressNewsProfiles();
                          }}
                          color="#000000"
                          size="sm"
                        >
                          <i className="tim-icons icon-double-right" />
                        </Button>
                        {' '}
                      </CardHeader>
                      <CardBody>
                        {sanctionsNewsProfilesTable ? (
                          <SimpleTable
                            thead={[
                              { text: 'Name' },
                              { text: 'Summary' },
                              { text: 'Certainty' },
                              { text: 'Reasons' },
                              { text: 'Short Summary' },
                              { text: 'Record Name Details' },
                            ]}
                            tbody={sanctionsNewsProfilesTable.map(
                              (profile) => ({
                                data: [
                                  { text: profile.Name },
                                  { text: profile.Summary },
                                  { text: profile.Certainty },
                                  { text: profile.Reasons },
                                  { text: profile.ShortSummary },
                                  { text: profile.RecordNameDetails },
                                ],
                              }),
                            )}
                          />
                        ) : (
                          <span> loading... </span>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
          )}

          {dndbProfilesTable && dndbProfilesTable.length > 0 && (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        DNDB Profiles for Request:
                        {requestid}
                      </CardTitle>
                      <Button
                        onClick={() => {
                          handlePressDNDBProfiles();
                        }}
                        color="#000000"
                        size="sm"
                      >
                        <i className="tim-icons icon-double-right" />
                      </Button>
                      {' '}
                    </CardHeader>
                    <CardBody>
                      {dndbProfilesTable ? (
                        <SimpleTable
                          thead={[
                            { text: 'Name' },
                            { text: 'Certainty' },
                            { text: 'Match Category' },
                          ]}
                          tbody={dndbProfilesTable.map((profile) => ({
                            data: [
                              { text: profile.Name },
                              { text: profile.Certainty },
                              { text: profile.MatchCategory },
                            ],
                          }))}
                        />
                      ) : (
                        <span> loading... </span>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      CIP Categories for Request:
                      {requestid}
                    </CardTitle>
                    <Button
                      onClick={() => {
                        handlePressCIPCategories();
                      }}
                      color="#000000"
                      size="sm"
                    >
                      <i className="tim-icons icon-double-right" />
                    </Button>
                    {' '}
                  </CardHeader>
                  <CardBody>
                    {cipCategoriesTable ? (
                      <SimpleTable
                        thead={[{ text: 'Category' }, { text: 'State' }]}
                        tbody={cipCategoriesTable.map((category) => ({
                          data: [
                            { text: category.cipCategory },
                            { text: category.categoryState },
                          ],
                        }))}
                      />
                    ) : (
                      <span> loading... </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{ width: '80%', marginLeft: '15%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Hedge Internal Investigation History for Account:
                      {' '}
                      {accountNum}
                    </CardTitle>
                    <Button
                      onClick={() => {
                        handleRefreshInternalHistory();
                      }}
                      color="#000000"
                      size="sm"
                    >
                      <i className="tim-icons icon-refresh-01" />
                    </Button>
                    <div
                      style={{
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '2%',
                        marginBottom: '2%',
                        borderWidth: '2px',
                        borderRadius: 10,
                        borderColor: 'grey',
                        borderStyle: 'solid',
                        padding: '5px',
                      }}
                    >
                      <h4>Update Internal Investigation State</h4>
                      <div>
                        <FormGroup
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Label
                            style={{ marginRight: '3%' }}
                            for="investigationStateSelect"
                          >
                            Select Internal Investigation State
                          </Label>
                          <Input
                            type="select"
                            name="select"
                            id="investigationStateSelect"
                            defaultValue="" // Set the default value to an empty string
                            onChange={(e) => {
                              setInvestigationDropDownValue(e.target.value);
                            }}
                          >
                            {/* Placeholder option */}
                            <option value="" disabled>
                              Select an option...
                            </option>
                            <option value="IDV_REQUESTED">IDV_REQUESTED</option>
                            <option value="FORM_RESUBMIT_REQUESTED">FORM_RESUBMIT_REQUESTED</option>
                            <option value="HEDGE_BACK_OFFICE">
                              HEDGE_BACK_OFFICE
                            </option>
                          </Input>
                        </FormGroup>

                        <Button
                          color="primary"
                          onClick={() => warningWithConfirmAndCancelMessage(
                            updateInvestigationMutation,
                            {
                              accountNum,
                              status: investigationDropDownValue,
                              requestId: requestid,
                            },
                            'updateInvestigationState',
                          )}
                        >
                          Update Account:
                          {' '}
                          {accountNum}
                          {' '}
                          Internal Investigation
                          State
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {internalInvestigationTable
                    && internalInvestigationTable.length > 0 ? (
                      <ReactTable
                        tableRoute="internalInvestigationHistory"
                        data={internalInvestigationTable}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'id',
                          },
                          {
                            Header: 'Type',
                            accessor: 'type',
                          },
                          {
                            Header: 'Account Number',
                            accessor: 'apexAccountNumber',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Sketch Request Id',
                            accessor: 'sketchRequestId',
                          },
                          {
                            Header: 'Created At',
                            accessor: 'dateTime',
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                      ) : (
                        <span> loading... </span>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {allIdentityDocumentsTable
            && allIdentityDocumentsTable.length > 0 && (
              <div
                style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
              >
                <Row className="mt-5">
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          All Identity Documents for Account:
                          {' '}
                          {accountNum}
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        {allIdentityDocumentsTable.length > 0 ? (
                          <IdentityTable
                            thead={[
                              { text: 'Document Type' },
                              { text: 'File' },
                            ]}
                            tbody={allIdentityDocumentsTable.map(
                              (document) => ({
                                data: [
                                  { text: document.documentType },
                                  {
                                    text: document.fileName,
                                    url: document.url, // Add the URL here
                                  },
                                ],
                              }),
                            )}
                          />
                        ) : (
                          <span> loading... </span>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
          )}
        </div>
      </div>
    </>
  );
}
