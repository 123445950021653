import React, { useState } from 'react';
import {
  Row, Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getIdentityDocsByAccountNumber } from '../../../Services/AccountServices'; // Correct path for API call
import IdentityTable from '../../Components/SortingTable/SortingTable'; // Corrected path for IdentityTable

function IdentityDocumentsTable({ accountNumber, userInfo }) {
  const [allIdentityDocumentsTable, setAllIdentityDocumentsTable] = useState([]);

  const { isLoading, isError, error } = useQuery(
    ['getIdentityDocs', accountNumber],
    () => getIdentityDocsByAccountNumber(accountNumber),
    {
      onSuccess: (data) => {
        setAllIdentityDocumentsTable(data || []);
      },
    },
  );

  if (isLoading) {
    return <p>Loading identity documents...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load identity documents.'}
      </p>
    );
  }

  return (
    allIdentityDocumentsTable.length > 0 && (
      <div style={{ width: '80%', margin: '0 auto' }}>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  All Identity Documents for Cognito Account:
                  {' '}
                  {userInfo?.User?.user_name}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <IdentityTable
                  thead={[{ text: 'Document Type' }, { text: 'File' }]}
                  tbody={allIdentityDocumentsTable.map((document) => ({
                    data: [
                      { text: document.documentType },
                      { text: document.fileName, url: document.url },
                    ],
                  }))}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
}

export default IdentityDocumentsTable;
