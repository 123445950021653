/* eslint-disable */

import { APIRequestWithAuth } from './config';

export const getAllDeadLetterQueues = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/awsMeta/deadLetterQueues');
  console.log('data', data);
  if (data) {
    return data;
  }
  return null;
};
