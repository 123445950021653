import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
} from 'reactstrap';
import UserDetailsTable from './components/UserDetailsTable';
import routes from '../../routes';
import Sidebar from '../Components/Sidebar/Sidebar';
import ApexAccountOverviewTable from './components/ApexAccountOverviewTable';
import AchRelationshipsTable from './components/AchRelationshipsTable';
import ApexAccountEntitlementsTable from './components/ApexAccountEntitlementsTable';
import CustomerProfileTable from './components/CustomerProfileTable';
import SuitabilityInfoTable from './components/SuitabilityInfoTable';
import FinancialProfileTable from './components/FinancialProfileTable';
import TrustedContactTable from './components/TrustedContactTable';
import IdentityDocumentsTable from './components/IdentityDocumentsTable';
import CloseAccountButton from './components/CloseAccountButton';

export default function AccountDetails() {
  const { accountNumber } = useParams();

  const tables = [
    { Component: UserDetailsTable, id: 'user-details' },
    { Component: ApexAccountOverviewTable, id: 'apex-account-overview' },
    { Component: AchRelationshipsTable, id: 'ach-relationships' },
    { Component: ApexAccountEntitlementsTable, id: 'apex-account-entitlements' },
    { Component: CustomerProfileTable, id: 'customer-profile' },
    { Component: SuitabilityInfoTable, id: 'suitability-info' },
    { Component: FinancialProfileTable, id: 'financial-profile' },
    { Component: TrustedContactTable, id: 'trusted-contact' },
    { Component: IdentityDocumentsTable, id: 'identity-documents' },
  ];

  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar routes={routes} activeColor="blue" />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Account Details</h2>
          <CloseAccountButton accountNumber={accountNumber} />
        </Col>

        {tables.map(({ Component, id }) => (
          <div key={id} style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Component accountNumber={accountNumber} />
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
}
