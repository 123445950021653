import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { getUserInfoByApexAccountNumber } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function UserDetailsTable({ accountNumber }) {
  const [userInfo, setUserInfo] = useState(null);

  const { isLoading, isError, error } = useQuery(
    ['getUser', accountNumber],
    () => getUserInfoByApexAccountNumber(accountNumber),
    {
      onSuccess: (data) => {
        setUserInfo(data);
      },
    },
  );

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Hedge User Info for Account:
            {' '}
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            <p>
              Error:
              {error?.message || 'Failed to load user information.'}
            </p>
          ) : (
            userInfo && (
              <SimpleTable
                thead={[
                  { text: 'First Name' },
                  { text: 'Last Name' },
                  { text: 'Email' },
                  { text: 'Cognito User' },
                ]}
                tbody={[
                  {
                    data: [
                      { text: userInfo.User?.first_name || 'N/A' },
                      { text: userInfo.User?.last_name || 'N/A' },
                      { text: userInfo.User?.email || 'N/A' },
                      { text: userInfo.User?.user_name || 'N/A' },
                    ],
                  },
                ]}
              />
            )
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default UserDetailsTable;
