import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function CustomerProfileTable({ accountNumber }) {
  const [customerProfileTable, setCustomerProfileTable] = useState([]);

  const { isLoading, isError, error } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (data) => {
        const customerProfile = [
          { label: 'Name', value: data?.customerProfile?.name || 'N/A' },
          { label: 'Address', value: data?.customerProfile?.address || 'N/A' },
          { label: 'DOB', value: data?.customerProfile?.dob || 'N/A' },
          { label: 'SSN', value: data?.customerProfile?.ssn || 'N/A' },
          { label: 'Phone Number', value: data?.customerProfile?.phone || 'N/A' },
          { label: 'Email', value: data?.customerProfile?.email || 'N/A' },
          { label: 'Citizenship', value: data?.customerProfile?.citizenship || 'N/A' },
          { label: 'Employment', value: data?.customerProfile?.employment || 'N/A' },
          { label: 'Employer Name', value: data?.customerProfile?.employerName || 'N/A' },
          { label: 'Job Title', value: data?.customerProfile?.jobTitle || 'N/A' },
          {
            label: 'Politically Exposed',
            value: data?.customerProfile?.politicallyExposed ? 'Yes' : 'No',
          },
          {
            label: 'Public Company',
            value: data?.customerProfile?.publicCompany ? 'Yes' : 'No',
          },
          {
            label: 'Broker Dealer',
            value: data?.customerProfile?.brokerDealer ? 'Yes' : 'No',
          },
        ];
        setCustomerProfileTable(customerProfile);
      },
    },
  );

  if (isLoading) {
    return <p>Loading customer profile...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load customer profile.'}
      </p>
    );
  }

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Customer Profile For:
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {customerProfileTable.length > 0 ? (
            <SimpleTable
              thead={[{ text: 'Label' }, { text: 'Value' }]}
              tbody={customerProfileTable.map((row) => ({
                data: [{ text: row.label }, { text: row.value }],
              }))}
            />
          ) : (
            <p>No profile information found.</p>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default CustomerProfileTable;
