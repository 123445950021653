import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable'; // Ensure the path is correct

function SuitabilityInfoTable({ accountNumber }) {
  const [suitabilityInfoTable, setSuitabilityInfoTable] = useState([]);

  const { isLoading, isError, error } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (data) => {
        const suitabilityInfo = [
          { label: 'Risk Tolerance', value: data?.suitabilityInfo?.riskTolerance || 'N/A' },
          { label: 'Experience', value: data?.suitabilityInfo?.experience || 'N/A' },
          { label: 'Investment Objective', value: data?.suitabilityInfo?.objective || 'N/A' },
          { label: 'Time Horizon', value: data?.suitabilityInfo?.timeHorizon || 'N/A' },
          { label: 'Liquidity Needs', value: data?.suitabilityInfo?.liquidityNeeds || 'N/A' },
        ];
        setSuitabilityInfoTable(suitabilityInfo);
      },
    },
  );

  if (isLoading) {
    return <p>Loading suitability info...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load suitability info.'}
      </p>
    );
  }

  return (
    suitabilityInfoTable.length > 0 && (
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Suitability Info For:
              {accountNumber}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <SimpleTable
              thead={[{ text: 'Label' }, { text: 'Value' }]}
              tbody={suitabilityInfoTable.map((row) => ({
                data: [{ text: row.label }, { text: row.value }],
              }))}
            />
          </CardBody>
        </Card>
      </Col>
    )
  );
}

export default SuitabilityInfoTable;
