import React from 'react';
import { Row, Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

function CloseAccountButton() {
  const [showAlert, setShowAlert] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);

  function handleDeleteClick() {
    setShowAlert(true);
  }

  function handleAlertConfirm() {
    // Handle delete logic here
    setDeletingAccount(true);
    setShowAlert(false);
  }

  function handleAlertCancel() {
    setShowAlert(false);
  }

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Button
        className="btn-round btn-just-icon"
        color="danger"
        disabled={deletingAccount}
        onClick={handleDeleteClick}
      >
        {deletingAccount ? 'Closing Account...' : 'Close Account'}
      </Button>
      <ReactBSAlert
        show={showAlert}
        title="Close Account"
        onConfirm={handleAlertConfirm}
        onCancel={handleAlertCancel}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, close it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to close this account?
      </ReactBSAlert>
    </Row>
  );
}

export default CloseAccountButton;
