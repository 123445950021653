import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function FinancialProfileTable({ accountNumber }) {
  const [financialProfileTable, setFinancialProfileTable] = useState([]);

  const { isLoading, isError, error } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (data) => {
        const financialProfile = [
          { label: 'Net Worth', value: data?.financialProfile?.netWorth || 'N/A' },
          { label: 'Liquid Net Worth', value: data?.financialProfile?.liquidNetWorth || 'N/A' },
          { label: 'Annual Income', value: data?.financialProfile?.annualIncome || 'N/A' },
        ];
        setFinancialProfileTable(financialProfile);
      },
    },
  );

  if (isLoading) {
    return <p>Loading financial profile...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load financial profile.'}
      </p>
    );
  }

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Financial Profile For:
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {financialProfileTable.length > 0 ? (
            <SimpleTable
              thead={[{ text: 'Label' }, { text: 'Value' }]}
              tbody={financialProfileTable.map((row) => ({
                data: [{ text: row.label }, { text: row.value }],
              }))}
            />
          ) : (
            <p>No financial profile data available.</p>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default FinancialProfileTable;
