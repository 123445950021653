/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getAllAchRelationships, getAchRelationshipById } from 'Services/AccountServices';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ReactTable from '../Components/ReactTable/ReactTable';

function AchRelationships() {
  // eslint-disable-next-line no-unused-vars
  const getAllAchRelationshipsQuery = useQuery('getAllAchRelationships', () => getAllAchRelationships(), {
    onSuccess: (data) => {
      // eslint-disable-next-line no-use-before-define
      setData(data.map((prop, key) => ({
        id: key,
        relationshipId: prop.relationshipid,
        status: prop?.status,
        reason: prop?.reason,
        account: prop?.account,
        correspondentCode: prop?.correspondentcode,
        approvalMethod: prop?.approvalmethod,
        uuid: prop.id,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressAch(prop?.relationshipid);
              }}
              color="#000000"
              size="sm"
              disabled={data?.length === 0}
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),
      })));
    },
  });

  let [data, setData] = [];

  const navigate = useNavigate();

  async function handlePressAch(achRelationshipId) {
    const achData = await getAchRelationshipById(achRelationshipId);
    navigate(`/admin/achrelationships/${achRelationshipId}`, { state: { achRelationshipData: [achData] } });
  }

  [data, setData] = React.useState(getAllAchRelationshipsQuery.data && getAllAchRelationshipsQuery.data.map((prop, key) => ({
    id: key,
    relationshipId: prop.relationshipid,
    status: prop?.status,
    reason: prop?.reason,
    account: prop?.account,
    correspondentCode: prop?.correspondentcode,
    approvalMethod: prop?.approvalmethod,
    uuid: prop.id,
    dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
    lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
    actions: (
    // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            handlePressAch(prop?.relationshipid);
          }}
          color="#000000"
          size="sm"
          disabled={data?.length === 0}
        >
          <i className="tim-icons icon-double-right" />
        </Button>
        {' '}

      </div>
    ),
  })));

  function handleRefresh() {
    getAllAchRelationshipsQuery.refetch();
  }

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">ACH Relationships</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={() => {
                      handleRefresh();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                  {' '}
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Relationship ID',
                          accessor: 'relationshipId',
                        },
                        {
                          Header: 'Overall Status',
                          accessor: 'status',
                        },
                        {
                          Header: 'Reason',
                          accessor: 'reason',
                        },
                        {
                          Header: 'Account',
                          accessor: 'account',
                        },
                        {
                          Header: 'Correspondent Code',
                          accessor: 'correspondentCode',
                        },
                        {
                          Header: 'Approval Method',
                          accessor: 'approvalMethod',
                        },
                        {
                          Header: 'UUID',
                          accessor: 'uuid',
                        },
                        {
                          Header: 'Date Submitted',
                          accessor: 'dateSubmitted',
                        },
                        {
                          Header: 'Last Updated',
                          accessor: 'lastUpdated',
                        },
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span> loading </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AchRelationships;
