// src/routes.js
/* eslint-disable import/no-unresolved */
/**
 * TODO: put route components here
 */
import Dashboard from 'Screens/Dashboard/Dashboard';
import Applications from 'Screens/Applications/Applications';
import UnmatchedEvents from 'Screens/Reconciliation/UnmatchedEvents';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    layout: '/admin',
    icon: 'tim-icons icon-components',
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
    layout: '/admin',
    icon: 'tim-icons icon-single-copy-04',
  },
  {
    path: '/accounts',
    name: 'Accounts',
    layout: '/admin',
    icon: 'tim-icons icon-single-02',
  },
  {
    path: '/transfers',
    name: 'Transfers',
    layout: '/admin',
    icon: 'tim-icons icon-money-coins',
  },
  {
    path: '/orders',
    name: 'Orders',
    layout: '/admin',
    icon: 'tim-icons icon-cart',
  },
  {
    path: '/achrelationships',
    name: 'ACH Relationships',
    layout: '/admin',
    icon: 'tim-icons icon-bank',
  },
  {
    path: '/acats',
    name: 'Acats',
    layout: '/admin',
    icon: 'tim-icons icon-double-right',
  },
  {
    path: '/alerts',
    name: 'Alerts',
    layout: '/admin',
    icon: 'tim-icons icon-bell-55',
  },
  {
    path: '/whitelist',
    name: 'Whitelist',
    layout: '/admin',
    icon: 'tim-icons icon-notes',
  },
  {
    path: '/tickerStatus',
    name: 'Ticker Status',
    layout: '/admin',
    icon: 'tim-icons icon-sound-wave',
  },
  {
    collapse: true,
    name: 'Reconciliation',
    icon: 'tim-icons icon-support-17',
    state: 'reconciliationCollapse',
    views: [
      {
        path: '/accountsReconciliation',
        name: 'Accounts Reconciliation',
        layout: '/admin',
        icon: 'tim-icons icon-badge',
      },
      {
        path: '/apexReconciliation',
        name: 'Apex Reconciliation',
        layout: '/admin',
        icon: 'tim-icons icon-badge',
      },
      {
        path: '/events/unmatched',
        name: 'Unmatched Events',
        component: UnmatchedEvents,
        layout: '/admin',
        icon: 'tim-icons icon-alert-circle-exc',
      },
    ],
  },
  {
    path: '/groups',
    name: 'Group Investing',
    layout: '/admin',
    icon: 'tim-icons icon-world',
  },
  {
    path: '/sqsqueues',
    name: 'SQS Queues',
    layout: '/admin',
    icon: 'tim-icons icon-send',
  },
];

export default routes;
